import axiosIns from './axios'

const utilsMixin = {
  methods: {
    /**
     * Sends files to a specified API endpoint using a POST request.
     * @param {string} endpoint - The API endpoint to send the files to.
     * @param {File[]} files - An array containing file objects to be sent.
     * @param {Object} additionalData - An object containing any additional data to be sent in the request.
     */
    async sendFiles(endpoint, files, additionalData) {
      const formData = new FormData()

      files.forEach(file => {
        formData.append('files[]', file)
      })

      try {
        Object.keys(additionalData).forEach(key => {
          formData.append(key, additionalData[key])
        })
      } catch (error) {
        console.error(error.message)
      }

      await axiosIns
        .post(endpoint, formData)
        .then(() => {})
        .catch(error => {
          console.log('utils: ', { error })

          this.$swal({
            title: 'Erro ao enviar arquivos',
            text: `Contate a equipe de desenvolvimento ${error}`,
            icon: 'error',
            timer: 4000,
            showConfirmButton: false,
          })
        })
    },

    /**
     * Extracts the file extension from a string.
     * @param {string} fileName The name of the file to be processed.
     * @returns {string} The file extension, including the initial dot.
     */
    extractFileExtension(fileName) {
      const fileExtensionRegex = /\.[^/.]+$/g
      const matches = fileName.match(fileExtensionRegex)

      if (matches == null) {
        return fileName
      }

      return matches[0]
    },

    /**
     * Extracts all email addresses from a given text string and returns them
     * as a semicolon-separated string. Returns an empty string if no email addresses are found.
     * @param {string} text - The text string to search for email addresses.
     * @returns {string} A semicolon-separated string containing all email addresses found, or an empty string if none are found.
     */
    extractEmailsFromString(text) {
      // const emailRegex = /[\w.]+@\w+\.\w{2,3}(\.\w{2,3})/g // regex to find email addresses
      const emailRegex = /[\w.+]+@\w+\.\w{2,}(\.\w{2,})*/g // regex to find email addresses
      const foundEmails = text.match(emailRegex) // finds all email addresses in the string

      if (!foundEmails) return ''

      const joinedEmails = foundEmails.join(';') // joins the email addresses with semicolons

      return joinedEmails
    },

    /**
     * Removes accents from a string.
     * @param {string} str - The string to remove accents from.
     * @returns {string} The string without accents.
     */
    removeAccents(str) {
      if (typeof str !== 'string') return 'parameter is not of type string'

      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },

    /**
     * Checks if any key in the given object has a non-empty value.
     * @param {object} obj - The object to check.
     * @returns {boolean} - True if any key has a non-empty value, false otherwise.
     */
    hasNonEmptyKey(obj) {
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(obj)) {
        if (value !== '' && value) {
          return true
        }
      }

      return false
    },

    /**
     * Gera uma cor hexadecimal aleatória.
     * @returns {string} A cor gerada no formato hexadecimal.
     */
    generateRandomHexColor() {
      const MAX_COLOR_VALUE = 16777215
      const randomDecimal = Math.random() * MAX_COLOR_VALUE
      const roundedDecimal = Math.floor(randomDecimal)
      const hexValue = roundedDecimal.toString(16).padStart(6, '0')
      const hexColor = `#${hexValue}`

      return hexColor
    },
  },
}

export default utilsMixin
