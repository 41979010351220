<template>
  <div>
    <v-card-title class="pt-0 pb-0">
      <div
        :class="{'toggle-btn-active' : active_btn_external, 'toggle-btn' : !active_btn_external}"
        @click="toggleBtnExternal"
      >
        <v-icon>
          {{ icons.mdiDockWindow }}
        </v-icon>

        <span>2° e 5° Parcela</span>
      </div>

      <div
        :class="{'toggle-btn-active' : active_btn_internal, 'toggle-btn' : !active_btn_internal}"
        @click="toggleBtnInternal"
      >
        <v-icon color="#fff">
          {{ icons.mdiTagArrowUpOutline }}
        </v-icon>

        <span>Bônus crescimento</span>
      </div>
    </v-card-title>

    <div class="container-sections">
      <SecondAndFifthParcel v-show="active_btn_external" />

      <GrowthBonus v-show="active_btn_internal" />
    </div>
  </div>
</template>
<script>
import {
  mdiAccountOutline, mdiAccountStarOutline, mdiAccountTieOutline,
  mdiDockWindow,
  mdiTagArrowUpOutline,
} from '@mdi/js'

import GrowthBonus from './tabs/GrowthBonus.vue'
import SecondAndFifthParcel from './tabs/SecondAndFifthParcel.vue'

export default {
  components: {
    SecondAndFifthParcel,
    GrowthBonus,
  },
  data() {
    return {
      active_btn_internal: false,
      active_btn_external: true,
      icons: {
        mdiAccountOutline,
        mdiAccountTieOutline,
        mdiAccountStarOutline,
        mdiTagArrowUpOutline,
        mdiDockWindow,
      },
    }
  },

  methods: {
    toggleBtnInternal() {
      this.active_btn_internal = true
      this.active_btn_external = false
    },

    toggleBtnExternal() {
      this.active_btn_internal = false
      this.active_btn_external = true
    },
  },

}
</script>
<style scoped>
.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-left: 20px;
}

.container-actions{
  display: flex;
  align-items: center;
}

.container-actions input {
  padding: 12px 28px;
  width: 90px;
  border-radius: 5px;
  border: 0.5px solid var(--primrias-opacidade-50, rgba(231, 227, 252, 0.50));
  outline: none;
  color: #E7E3FC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-inputs{
  display: flex;
  max-width: 800px;
}

.section-inputs .container-commisions{
  width: 350px;
  margin: 12px 20px 0px 20px;
}

.container-bonnification{
  width: 450px;
  background: #ccc;
}

.toggle-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 0px;

  width: 200px;
}

.toggle-btn span{
  font-size: 14px;
  color: #fff
}

.toggle-btn-active{
  background: #16B1FF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 0px;

  width: 200px;
}

.toggle-btn-active span{
  font-size: 14px;
  color: #fff
}

.container-sections{
  margin: 25px 25px;
}
</style>
