<template>
  <div>
    <v-card class="pl-8 pt-8 pb-8 pr-8 d-flex">
      <div class="infos">
        <div class="d-flex">
          <v-icon
            size="30"
            class="mr-2"
          >
            {{ icons.mdiCogOutline }}
          </v-icon>

          <p class="mt-1">
            Regra geral para

            <strong style="color: rgba(239, 112, 255, 1)">
              pagamento
            </strong>

            de bônus de 2° e 5° parcela
          </p>
        </div>

        <div class="d-flex align-center">
          <p class="mt-4 mr-2">
            O pagamento é realizado no
          </p>

          <input
            v-model="formattedMonthValue"
            :disabled="saveOrEdit"
          />

          <p class="mt-4 ml-2">
            mês após a safra.
          </p>
        </div>
      </div>

      <div class="actions">
        <v-tooltip
          v-if="saveOrEdit"
          left
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toggleSaveToEdit"
            >
              <v-icon
                color="info"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>

          <span>Editar</span>
        </v-tooltip>

        <v-tooltip
          v-else-if="!saveOrEdit && !loadingUpdate"
          left
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="saveNewCommisionsSecondAndFifth()"
            >
              <v-icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiContentSaveOutline }}
              </v-icon>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>

        <v-progress-circular
          v-else
          color="success"
          indeterminate
          size="20"
        >
        </v-progress-circular>

        <div class="mt-6">
          <v-tooltip
            left
            color="secondary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationVariant }}
                </v-icon>
              </v-btn>
            </template>

            <span>Exemplo:
              Safra de Janeiro recebe o bônus em Julho (6 meses após)</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>

    <v-card class="pl-8 pt-8 pb-8 pr-8 mt-4 d-flex">
      <div class="infos">
        <div class="d-flex">
          <v-icon
            size="30"
            class="mr-2"
          >
            {{ icons.mdiCheckDecagramOutline }}
          </v-icon>

          <p class="mt-1">
            Quem

            <strong style="color: rgba(239, 112, 255, 1)">
              está
            </strong>

            habilitado a receber?
          </p>
        </div>

        <div class="line d-flex align-center">
          <p class="mt-4 mr-2">
            Os consultores com adimplência entre
          </p>

          <input
            v-model="formattedValuePercentageOneInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            a
          </p>

          <input
            v-model="formattedValuePercentageTwoInitial"
            :disabled="saveOrEditCanReceive"
            style="width: 95px"
          />

          <p class="mt-4 mr-2">
            conquista
          </p>

          <input
            v-model="formattedValuePercentageThreeInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            de bonificação
          </p>
        </div>

        <div class="line d-flex align-center  mt-4">
          <p class="mt-4 mr-2">
            Os consultores com adimplência entre
          </p>

          <input
            v-model="formattedValuePercentageFourInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            a
          </p>

          <input
            v-model="formattedValuePercentageFiveInitial"
            :disabled="saveOrEditCanReceive"
            style="width: 95px"
          />

          <p class="mt-4 mr-2">
            conquista
          </p>

          <input
            v-model="formattedValuePercentageSixInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            de bonificação
          </p>
        </div>

        <div class="line d-flex align-center mt-4">
          <p class="mt-4 mr-2">
            Os consultores com adimplência a partir de
          </p>

          <input
            v-model="formattedValuePercentageSevenInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            conquista
          </p>

          <input
            v-model="formattedValuePercentageEightInitial"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4 mr-2">
            de bonificação
          </p>
        </div>
      </div>

      <div class="actions-second-card">
        <v-tooltip
          v-if="saveOrEditCanReceive"
          left
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toggleSaveOrEditCanReceive"
            >
              <v-icon
                color="info"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>

          <span>Editar</span>
        </v-tooltip>

        <v-tooltip
          v-else-if="!saveOrEditCanReceive && !loadingUpdateCanReceived"
          left
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="saveNewCommisionsCanReceive()"
            >
              <v-icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiContentSaveOutline }}
              </v-icon>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>
        <v-progress-circular
          v-else
          color="success"
          indeterminate
          size="20"
        >
        </v-progress-circular>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountTieOutline,
  mdiCheckDecagramOutline,
  mdiCogOutline, mdiContentSaveOutline,
  mdiInformationVariant,
  mdiPencil,
} from '@mdi/js'

import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      loadingUpdateCanReceived: false,
      saveOrEdit: true,
      saveOrEditCanReceive: true,
      idToEdit: '',
      commisionsJuniorsInternalData: {},
      showModal: false,
      icons: {
        mdiAccountTieOutline,
        mdiCheckDecagramOutline,
        mdiCogOutline,
        mdiContentSaveOutline,
        mdiPencil,
        mdiInformationVariant,
      },
      loadingData: false,
      dataInfo: {},
      loadingUpdate: false,
    }
  },

  computed: {
    formattedMonthValue: {
      get() {
        if (!this.dataInfo.bonus_payment_interval) return 0

        if (!this.saveOrEdit) return this.dataInfo.bonus_payment_interval

        return `${Number(this.dataInfo.bonus_payment_interval)}°`
      },

      set(value) {
        this.dataInfo.bonus_payment_interval = Number(value)
      },
    },

    formattedValuePercentageOneInitial: {
      get() {
        if (!this.dataInfo.compliance_one_min) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_one_min

        return `${(Number(this.dataInfo.compliance_one_min))}%`
      },
      set(value) {
        this.dataInfo.compliance_one_min = Number(value)
      },
    },

    formattedValuePercentageTwoInitial: {
      get() {
        if (!this.dataInfo.compliance_one_max) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_one_max

        return `${(Number(this.dataInfo.compliance_one_max))}%`
      },
      set(value) {
        this.dataInfo.compliance_one_max = Number(value)
      },
    },

    formattedValuePercentageThreeInitial: {
      get() {
        if (!this.dataInfo.compliance_one_min_max_bonus) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_one_min_max_bonus

        return `${(Number(this.dataInfo.compliance_one_min_max_bonus))}%`
      },
      set(value) {
        this.dataInfo.compliance_one_min_max_bonus = Number(value)
      },
    },

    formattedValuePercentageFourInitial: {
      get() {
        if (!this.dataInfo.compliance_two_min) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_two_min

        return `${(Number(this.dataInfo.compliance_two_min))}%`
      },
      set(value) {
        this.dataInfo.compliance_two_min = Number(value)
      },
    },

    formattedValuePercentageFiveInitial: {
      get() {
        if (!this.dataInfo.compliance_two_max) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_two_max

        return `${(Number(this.dataInfo.compliance_two_max))}%`
      },
      set(value) {
        this.dataInfo.compliance_two_max = Number(value)
      },
    },

    formattedValuePercentageSixInitial: {
      get() {
        if (!this.dataInfo.compliance_two_min_max_bonus) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_two_min_max_bonus

        return `${(Number(this.dataInfo.compliance_two_min_max_bonus))}%`
      },
      set(value) {
        this.dataInfo.compliance_two_min_max_bonus = Number(value)
      },
    },

    formattedValuePercentageSevenInitial: {
      get() {
        if (!this.dataInfo.compliance_three_min) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_three_min

        return `${(Number(this.dataInfo.compliance_three_min))}%`
      },
      set(value) {
        this.dataInfo.compliance_three_min = Number(value)
      },
    },

    formattedValuePercentageEightInitial: {
      get() {
        if (!this.dataInfo.compliance_three_min_bonus) return 0

        if (!this.saveOrEditCanReceive) return this.dataInfo.compliance_three_min_bonus

        return `${(Number(this.dataInfo.compliance_three_min_bonus))}%`
      },
      set(value) {
        this.dataInfo.compliance_three_min_bonus = Number(value)
      },
    },
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      this.loadingData = true

      try {
        const response = await axiosIns.get('/api/v1/integration/bonus_parameter/index')

        this.dataInfo = {
          id: response.data.data[0].id,
          compliance_qualify_installment_bonus: response.data.data[0].compliance_qualify_installment_bonus,
          bonus_payment_interval: response.data.data[0].bonus_payment_interval,
          compliance_one_min: response.data.data[0].compliance_one_min,
          compliance_one_max: response.data.data[0].compliance_one_max,
          compliance_one_min_max_bonus: response.data.data[0].compliance_one_min_max_bonus,
          compliance_two_min: response.data.data[0].compliance_two_min,
          compliance_two_max: response.data.data[0].compliance_two_max,
          compliance_two_min_max_bonus: response.data.data[0].compliance_two_min_max_bonus,
          compliance_three_min: response.data.data[0].compliance_three_min,
          compliance_three_min_bonus: response.data.data[0].compliance_three_min_bonus,
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingData = false
      }
    },
    editData() {

    },
    toggleSaveToEdit() {
      this.saveOrEdit = !this.saveOrEdit
    },
    toggleSaveOrEditCanReceive() {
      this.saveOrEditCanReceive = !this.saveOrEditCanReceive
    },

    async saveNewCommisionsCanReceive() {
      this.loadingUpdateCanReceived = true

      const body = {
        compliance_qualify_installment_bonus: this.dataInfo.compliance_qualify_installment_bonus,
        compliance_one_min: this.dataInfo.compliance_one_min,
        compliance_one_max: this.dataInfo.compliance_one_max,
        compliance_one_min_max_bonus: Number(this.dataInfo.compliance_one_min_max_bonus) / 100,
        compliance_two_min: this.dataInfo.compliance_two_min,
        compliance_two_max: this.dataInfo.compliance_two_max,
        compliance_two_min_max_bonus: Number(this.dataInfo.compliance_two_min_max_bonus) / 100,
        compliance_three_min: this.dataInfo.compliance_three_min,
        compliance_three_min_bonus: Number(this.dataInfo.compliance_three_min_bonus) / 100,
      }

      try {
        await axiosIns.put(`/api/v1/integration/bonus_parameter/update/${this.dataInfo.id}`, body)
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.loadingUpdateCanReceived = false
        this.saveOrEditCanReceive = true
        this.updatedTable()
      }

      // this.saveOrEditCanReceive = true
    },

    async saveNewCommisionsSecondAndFifth() {
      this.loadingUpdate = true

      const body = {
        bonus_payment_interval: this.dataInfo.bonus_payment_interval,
        compliance_qualify_installment_bonus: this.dataInfo.compliance_qualify_installment_bonus,
        compliance_one_min: this.dataInfo.compliance_one_min,
        compliance_one_max: this.dataInfo.compliance_one_max,
        compliance_one_min_max_bonus: Number(this.dataInfo.compliance_one_min_max_bonus) / 100,
        compliance_two_min: this.dataInfo.compliance_two_min,
        compliance_two_max: this.dataInfo.compliance_two_max,
        compliance_two_min_max_bonus: Number(this.dataInfo.compliance_two_min_max_bonus) / 100,
        compliance_three_min: this.dataInfo.compliance_three_min,
        compliance_three_min_bonus: Number(this.dataInfo.compliance_three_min_bonus) / 100,
      }

      try {
        await axiosIns.put(`/api/v1/integration/bonus_parameter/update/${this.dataInfo.id}`, body)
      } catch (error) {
        console.log(error)
        this.showErrorMessage(error)
      } finally {
        this.loadingUpdate = false
        this.saveOrEdit = true
        this.updatedTable()
      }
    },

    updatedTable() {
      this.getData()
    },
  },
}
</script>
<style scoped>
.card {
  display: flex;
  align-items: center;
}
.card-infos{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
}

.card-infos span{
  color: #E7E3FC
}

.card-infos small{
  color: #E7E3FC
}

input {
  padding: 8px 18px;
  width: 90px;
  border-radius: 5px;
  border: 0.5px solid  rgba(231, 227, 252, 0.50);
  outline: none;
  color: #E7E3FC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infos{
  width: 90%;
}

.actions{
  width: 10%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.actions-second-card{
  width: 10%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.line{
  gap: 8px;

  display: flex;
  align-items: center;
}

.line p {
  font-size: 15px;
}
</style>
