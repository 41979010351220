<template>
  <div>
    <v-card class="pl-8 pt-8 pb-8 pr-8 d-flex">
      <div class="infos">
        <div class="d-flex">
          <v-icon
            size="30"
            class="mr-2"
          >
            {{ icons.mdiCogOutline }}
          </v-icon>

          <p class="mt-1">
            Regra geral para

            <strong style="color: rgba(239, 112, 255, 1)">
              recebimento
            </strong>

            de bônus de crescimento
          </p>
        </div>

        <div class="d-flex align-center line-infos">
          <p class="mt-4 mr-2">
            Para credenciar o recebimento de bônus de crescimento, o consultor deve atingir
          </p>

          <input
            v-model="formattedPercentage"
            :disabled="saveOrEdit"
          />

          <p class="mt-4 mr-2 ml-2">
            de adimplência na
          </p>

          <input
            v-model="formattedNumberParcel"
            :disabled="saveOrEdit"
          />

          <p class="mt-4 ml-2">
            parcela
          </p>
        </div>

        <div class="d-flex align-center line-infos">
          <p class="mt-4 mr-2">
            no
          </p>

          <input
            v-model="formattedNumberMonth"
            :disabled="saveOrEdit"
          />

          <p class="mt-4 ml-2">
            mês após a safra.
          </p>
        </div>
      </div>

      <div class="actions">
        <v-tooltip
          v-if="saveOrEdit"
          left
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toggleSaveToEdit"
            >
              <v-icon
                color="info"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip
          v-else-if="!saveOrEdit && !loadingUpdate"
          left
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="saveNewCommisionsSecondAndFifth()"
            >
              <v-icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiContentSaveOutline }}
              </v-icon>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>
        <v-progress-circular
          v-else
          color="success"
          indeterminate
          size="20"
        >
        </v-progress-circular>

        <div class="mt-6">
          <v-tooltip
            left
            color="secondary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationVariant }}
                </v-icon>
              </v-btn>
            </template>

            <span>Exemplo:
              Safra de Janeiro recebe o bônus em Julho (6 meses após)</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>

    <v-card class="pl-8 pt-8 pb-8 pr-8 mt-4 d-flex">
      <div class="infos">
        <div class="d-flex">
          <v-icon
            size="30"
            class="mr-2"
          >
            {{ icons.mdiLayersOutline }}
          </v-icon>

          <p class="mt-1">
            Regras para

            <strong style="color: rgba(239, 112, 255, 1)">
              recebimento
            </strong>

            do bônus de crescimento
          </p>
        </div>
        <div class="line d-flex align-center">
          <p class="mt-4">
            Os consultores

            <strong style="color: rgba(239, 112, 255, 1)">
              INTERNOS
            </strong>

            precisam atingir no mínimo
          </p>

          <input
            v-model="dataInfo.internal_goal_min"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas para habilitar-se ao bônus de
          </p>

          <input
            v-model="formattedValueInternal"
            style="width: 120px;"
            :disabled="saveOrEditCanReceive"
          />
        </div>

        <div class="line d-flex align-center mt-2">
          <p class="mt-4">
            Os consultores nível

            <strong style="color: rgba(239, 112, 255, 1)">
              JÚNIOR
            </strong>

            meta de
          </p>

          <input
            v-model="dataInfo.level_junior_goal_min"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            a
          </p>

          <input
            v-model="dataInfo.level_junior_goal_max"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas, conquistam
          </p>

          <input
            v-model="formattedValueJunior"
            style="width: 120px;"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            a cada
          </p>

          <input
            v-model="dataInfo.level_junior_exceed_goal"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas
          </p>
        </div>

        <div class="line">
          <p>que ultrapassar da sua meta padrão.</p>
        </div>

        <div class="line d-flex align-center">
          <p class="mt-4">
            Os consultores nível

            <strong style="color: rgba(239, 112, 255, 1)">
              PLENO
            </strong>

            meta de
          </p>

          <input
            v-model="dataInfo.level_pleno_goal_min"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            a
          </p>

          <input
            v-model="dataInfo.level_pleno_goal_max"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas, conquistam
          </p>

          <input
            v-model="formattedValuePleno"
            style="width: 120px;"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            a cada
          </p>

          <input
            v-model="dataInfo.level_pleno_exceed_goal"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas
          </p>
        </div>

        <div class="line">
          <p>que ultrapassar da sua meta padrão.</p>
        </div>

        <div class="line d-flex align-center">
          <p class="mt-4">
            Os consultores nível

            <strong style="color: rgba(239, 112, 255, 1)">
              SÊNIOR
            </strong>

            meta de
          </p>

          <input
            v-model="dataInfo.level_senior_goal_min"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            ou + cotas conquistam
          </p>

          <input
            v-model="formattedValueSenior"
            style="width: 120px;"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            a cada
          </p>

          <input
            v-model="dataInfo.level_senior_exceed_goal"
            :disabled="saveOrEditCanReceive"
          />

          <p class="mt-4">
            cotas que ultrapassar da
          </p>
        </div>

        <div class="line">
          <p>sua meta padrão.</p>
        </div>

        <div class="line">
          <p class="mt-4">
            Os consultores nível

            <strong style="color: rgba(239, 112, 255, 1)">
              ELITE
            </strong>

            seguem os mesmos critérios e valores do nível sênior para bônus de crescimento.
          </p>
        </div>
      </div>

      <div class="actions-second-card">
        <v-tooltip
          v-if="saveOrEditCanReceive"
          left
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toggleSaveOrEditCanReceive"
            >
              <v-icon
                color="info"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip
          v-else-if="!saveOrEditCanReceive && !loadingUpdateCanReceived"
          left
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="saveNewCommisionsCanReceive()"
            >
              <v-icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiContentSaveOutline }}
              </v-icon>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>
        <v-progress-circular
          v-else
          color="success"
          indeterminate
          size="20"
        >
        </v-progress-circular>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiAccountTieOutline,
  mdiCheckDecagramOutline,
  mdiCogOutline, mdiContentSaveOutline,
  mdiInformationVariant,
  mdiLayersOutline,
  mdiPencil,
} from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      loadingUpdate: false,
      loadingUpdateCanReceived: false,
      saveOrEdit: true,
      saveOrEditCanReceive: true,
      icons: {
        mdiLayersOutline,
        mdiAccountTieOutline,
        mdiCheckDecagramOutline,
        mdiCogOutline,
        mdiContentSaveOutline,
        mdiPencil,
        mdiInformationVariant,
      },
      dataInfo: {},
    }
  },

  computed: {
    formattedPercentage: {
      get() {
        if (!this.dataInfo.compliance_qualify_installment_bonus) return 0

        if (!this.saveOrEdit) return this.dataInfo.compliance_qualify_installment_bonus

        return `${Number(this.dataInfo.compliance_qualify_installment_bonus)}%`
      },

      set(value) {
        this.dataInfo.compliance_qualify_installment_bonus = Number(value)
      },
    },

    formattedNumberParcel: {
      get() {
        if (!this.saveOrEdit) return this.dataInfo.installment_payment

        return `${Number(this.dataInfo.installment_payment)}°`
      },
      set(value) {
        this.dataInfo.installment_payment = Number(value)
      },
    },

    formattedNumberMonth: {
      get() {
        if (!this.saveOrEdit) return this.dataInfo.ihs_calculation_interval

        return `${Number(this.dataInfo.ihs_calculation_interval)}°`
      },
      set(value) {
        this.dataInfo.ihs_calculation_interval = Number(value)
      },
    },

    formattedValueInternal: {
      get() {
        if (!this.saveOrEditCanReceive) return this.dataInfo.internal_goal_value

        return `${this.formatBrazilianCurrency(Number(this.dataInfo.internal_goal_value).toFixed(2))}`
      },
      set(value) {
        this.dataInfo.internal_goal_value = Number(value)
      },
    },

    formattedValueJunior: {
      get() {
        if (!this.saveOrEditCanReceive) return this.dataInfo.level_junior_goal_value

        return `${this.formatBrazilianCurrency(Number(this.dataInfo.level_junior_goal_value).toFixed(2))}`
      },
      set(value) {
        this.dataInfo.level_junior_goal_value = Number(value)
      },
    },

    formattedValuePleno: {
      get() {
        if (!this.saveOrEditCanReceive) return this.dataInfo.level_pleno_goal_value

        return `${this.formatBrazilianCurrency(Number(this.dataInfo.level_pleno_goal_value).toFixed(2))}`
      },
      set(value) {
        this.dataInfo.level_pleno_goal_value = Number(value)
      },
    },

    formattedValueSenior: {
      get() {
        if (!this.saveOrEditCanReceive) return this.dataInfo.level_senior_goal_value

        return `${this.formatBrazilianCurrency(Number(this.dataInfo.level_senior_goal_value).toFixed(2))}`
      },
      set(value) {
        this.dataInfo.level_senior_goal_value = Number(value)
      },
    },
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      this.loadingData = true

      try {
        const response = await axiosIns.get('/api/v1/integration/growth_bonus/index')

        this.dataInfo = {
          id: response.data.data[0].id,
          installment_payment: response.data.data[0].installment_payment,
          internal_goal_min: response.data.data[0].internal_goal_min,
          internal_goal_value: response.data.data[0].internal_goal_value,
          level_junior_goal_min: response.data.data[0].level_junior_goal_min,
          level_junior_goal_max: response.data.data[0].level_junior_goal_max,
          level_junior_goal_value: response.data.data[0].level_junior_goal_value,
          level_junior_exceed_goal: response.data.data[0].level_junior_exceed_goal,
          level_pleno_goal_min: response.data.data[0].level_pleno_goal_min,
          level_pleno_goal_max: response.data.data[0].level_pleno_goal_max,
          level_pleno_goal_value: response.data.data[0].level_pleno_goal_value,
          level_pleno_exceed_goal: response.data.data[0].level_pleno_exceed_goal,
          level_senior_goal_min: response.data.data[0].level_senior_goal_min,
          level_senior_goal_value: response.data.data[0].level_senior_goal_value,
          level_senior_exceed_goal: response.data.data[0].level_senior_exceed_goal,
          level_elite_goal_min: response.data.data[0].level_elite_goal_min,
          level_elite_goal_value: response.data.data[0].level_elite_goal_value,
          level_elite_exceed_goal: response.data.data[0].level_elite_exceed_goal,
          compliance_qualify_installment_bonus: response.data.data[0].compliance_qualify_installment_bonus,
          ihs_calculation_interval: response.data.data[0].ihs_calculation_interval,
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingData = false
      }
    },
    toggleSaveToEdit() {
      this.saveOrEdit = !this.saveOrEdit
    },

    toggleSaveOrEditCanReceive() {
      this.saveOrEditCanReceive = !this.saveOrEditCanReceive
    },

    async saveNewCommisionsSecondAndFifth() {
      this.loadingUpdate = true

      const body = {
        installment_payment: this.dataInfo.installment_payment,
        compliance_qualify_installment_bonus: this.dataInfo.compliance_qualify_installment_bonus,
        ihs_calculation_interval: this.dataInfo.ihs_calculation_interval,
      }

      try {
        await axiosIns.put(`/api/v1/integration/growth_bonus/update/${this.dataInfo.id}`, body)
      } catch (error) {
        console.log(error)
        this.showErrorMessage(error)
      } finally {
        this.loadingUpdate = false
        this.saveOrEdit = true
        this.updatedTable()
      }
    },

    async saveNewCommisionsCanReceive() {
      this.loadingUpdateCanReceived = true

      const body = {
        internal_goal_min: this.dataInfo.internal_goal_min,
        internal_goal_value: this.dataInfo.internal_goal_value,
        level_junior_goal_min: this.dataInfo.level_junior_goal_min,
        level_junior_goal_max: this.dataInfo.level_junior_goal_max,
        level_junior_goal_value: this.dataInfo.level_junior_goal_value,
        level_junior_exceed_goal: this.dataInfo.level_junior_exceed_goal,
        level_pleno_goal_min: this.dataInfo.level_pleno_goal_min,
        level_pleno_goal_max: this.dataInfo.level_pleno_goal_max,
        level_pleno_goal_value: this.dataInfo.level_pleno_goal_value,
        level_pleno_exceed_goal: this.dataInfo.level_pleno_exceed_goal,
        level_senior_goal_min: this.dataInfo.level_senior_goal_min,
        level_senior_goal_value: this.dataInfo.level_senior_goal_value,
        level_senior_exceed_goal: this.dataInfo.level_senior_exceed_goal,
      }

      try {
        await axiosIns.put(`/api/v1/integration/growth_bonus/update/${this.dataInfo.id}`, body)
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.loadingUpdateCanReceived = false
        this.saveOrEdit = true
        this.updatedTable()
      }
    },

    updatedTable() {
      this.getData()
    },
  },
}
</script>
<style scoped>
.infos{
  width: 100%;
}

input {
  padding: 8px 18px;
  width: 90px;
  border-radius: 5px;
  border: 0.5px solid  rgba(231, 227, 252, 0.50);
  outline: none;
  color: #E7E3FC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-infos p {
  font-size: 13px;
}

.actions{
  width: 10%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.line{
  gap: 8px;

  display: flex;
  align-items: center;
}

.line p {
  font-size: 13px;
}

.actions-second-card{
  width: 10%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
</style>
